import { render, staticRenderFns } from "./redirect.vue?vue&type=template&id=065155cc"
var script = {}
import style0 from "./redirect.vue?vue&type=style&index=0&id=065155cc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.11.1_2aqslel7prtkk4onzp5d3vudie/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports